$white: #fff;
$black: #000;
$light_black: #252a35;

$blue: #005eb8;
$dark_blue: #0072ce;

$text_dark_primary: #040404;
$text_dark_secondary: #101010;

$text_light_primary: #f8f8f8;
$text_light_secondary: #fbf9f7;

$gray_primary: #98a0a8;
$gray_secondary: #d1d7dd;

$green: #284829;

$background: #d9d9d9;
$background_primary: #0f161b;
$background_gray: #faf9f8;
