@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
}
